import _ from 'lodash'


export const formatTotalElements = num => _.isNil(num) ? '' : (num + ' element' + (num === 1 ? 'o' : 'i' ))

export const getQueryParams = query => ({
  perPage: query.pageSize,
  page: query.page + 1,
  search: query.search,
  orderByField: _.isNil(query.orderBy) ? query.orderBy : query.orderBy.field,
  orderDirection: query.orderDirection
})


const getHeaderStyle = () => ({
  backgroundColor: '#d32f2f',
  color: '#FFF',
  paddingTop: '4px',
  paddingBottom: '4px',
})

export const primaryTableOptions = {
  pageSize: 20,
  pageSizeOptions: [10, 20, 50, 100, 500],
  search: true,
  selection: true,
  padding: 'dense'
}

export const getPrimaryTableOptions = isColumnsFiltersEnabled => ({
  ...primaryTableOptions,
  filtering: isColumnsFiltersEnabled
})

export const cardTableOptions = {
  pageSize: 5,
  pageSizeOptions: [5, 10, 20, 50, 100],
  search: true,
  selection: false,
  padding: 'dense',
  actionsColumnIndex: -1,
  addRowPosition: 'first',
}

export const secondaryTableOptions = {
  pageSize: 20,
  pageSizeOptions: [10, 20, 50, 100, 500],
  search: true,
  selection: false,
  padding: 'dense',
  actionsColumnIndex: -1,
  addRowPosition: 'first',
}

export const readonlyTableOptions = {
  toolbar: false,
  paging: false,
  search: false,
  draggable: false,
  padding: 'dense',
  headerStyle: getHeaderStyle()
}

export const dialogTableOptions = {
  draggable: false,
  pageSize: 10,
  actionsColumnIndex: -1,
  padding: 'dense',
  addRowPosition: 'first',
  headerStyle: getHeaderStyle()
}

export const selectableFormTableOptions = {
  draggable: false,
  paging: false,
  actionsColumnIndex: -1,
  padding: 'dense',
  headerStyle: {
    backgroundColor: '#d32f2f',
    color: '#FFF',
    paddingTop: '4px',
    paddingBottom: '4px',
  }
}

export const getSelectableFormTableOptions = (isShowAll, ids) => ({
  ...selectableFormTableOptions,
  rowStyle: rowData => ({
    backgroundColor: isShowAll ? (ids.includes(rowData.id) ? '#DDD' : '#FFF') : '#FFF'
  })
})

export const formTableOptions = {
  draggable: false,
  paging: false,
  actionsColumnIndex: -1,
  padding: 'dense',
  headerStyle: {
    backgroundColor: '#d32f2f',
    color: '#FFF',
    paddingTop: '4px',
    paddingBottom: '4px',
  }
}



export const localization = {
  header: {
    actions: 'Azioni'
  },
  body: {
    emptyDataSourceMessage: 'Nessun risultato',
    addTooltip: 'Crea nuovo',
    editTooltip: 'Modifica',
    deleteTooltip: 'Elimina',
    editRow: {
      deleteText: 'Eliminare questo elemento?'
    }
  },
  toolbar: {
    searchTooltip: 'Cerca',
    nRowsSelected: 'Elementi selezionati: {0}',
    searchPlaceholder: 'Cerca'
  },
  pagination: {
    labelRowsSelect: 'elementi per pagina',
    labelDisplayedRows: ' {from}-{to} di {count}',
    firstTooltip: 'Prima pagina',
    previousTooltip: 'Pagina precedente',
    nextTooltip: 'Pagina successiva',
    lastTooltip: 'Ultima pagina'
  }
}