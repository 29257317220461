import { matchPath } from "react-router"


export const routes = {
  login: '/login',
  lostPassword: '/password-dimenticata',
  resetPassword: '/reimposta-password',
  home: '/',
  applications: '/applicazioni',
  products: '/prodotti',
  classparts: '/classi-parte',
  instanceparts: '/istanze-parte',
  patternmixes: '/patternmix',
  patterns: '/pattern',
  colors: '/colori',
  materials: '/materiali',
  geometries: '/geometrie',
  maps: '/mappe',
  compositions: '/composizioni',
  configuredProducts: '/prodotti-configurati',
  configuredParts: '/parti-configurate',
  statistics: '/statistiche',
  users: '/utenti',
  roles: '/ruoli',
  permissions: '/permessi',
}

export const isKnownPath = (knownPaths, path) => {
  for (const knownPath of knownPaths) {
    const matchedPath = matchPath(path, { path: knownPath, exact: true, strict: false })
    if (matchedPath) return true
  }
  return false
}

export const replaceEditPath = (path, replacement) => path.replace(':id', replacement)