import React from "react"
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom'
import { PrivateRoute, LoginRoute } from "./CustomRoute"
import MainPage from "../../container/MainPage"
import Login from "../../container/auth/Login"
import ForgotPassword from "../../container/auth/ForgotPassword"
import ResetPassword from "../../container/auth/ResetPassword"
import SnackbarNotifier from "../../component/layout/SnackbarNotifier"
import { routes } from '../../helper/routes'


export default function MainRouter() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <LoginRoute path={routes.login} component={Login} />
        <Route path={routes.lostPassword} component={ForgotPassword} />
        <Route path={routes.resetPassword} component={ResetPassword} />
        <PrivateRoute path={routes.home} component={MainPage} />
        <Redirect to={routes.home} />
      </Switch>
      <SnackbarNotifier />
    </BrowserRouter>
  )
}
