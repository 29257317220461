import React from 'react'
import { connect } from 'react-redux'
import DynamicTable from '../../component/DynamicTable'
import configuredProductActions from '../../action/configuredProduct'
import { localization, secondaryTableOptions, formatTotalElements, getQueryParams } from '../../helper/table'


function ConfiguredProductsTable({ dispatch, totalCount }) {

  const fetchPaginatedData = query => dispatch(configuredProductActions.readPaginated(getQueryParams(query)))

  return (
    <DynamicTable
      columns={[
        { title: "Id", field: "id" },
        { title: "Uid", field: "configured_product_id" },
        { title: "Pos X", field: "pos_x" },
        { title: "Pos Y", field: "pos_y" },
        { title: "Pos Z", field: "pos_z" },
        { title: "Rot X", field: "rot_x" },
        { title: "Rot Y", field: "rot_y" },
        { title: "Rot Z", field: "rot_z" },
        { title: "Prodotto", field: "product_title" },
        { title: "Composizione", field: "composition_uid" },
        { title: "App", field: "app_title" },
        { title: "Abilitato", field: "is_disabled", render: rowData => !rowData.is_disabled ? 'Si' : 'No' },
        { title: "Extra", field: "extra" },
      ]}
      data={fetchPaginatedData}
      title={formatTotalElements(totalCount)}
      options={secondaryTableOptions}
      localization={localization}
    />
  )
}

const mapStateToProps = store => {
  const { configuredProduct } = store
  return {
    totalCount: configuredProduct.totalCount
  }
}


export default connect(mapStateToProps)(ConfiguredProductsTable)