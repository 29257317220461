import React from "react"
import { Route, Redirect } from 'react-router-dom'
import authHelper from '../../helper/auth'
import { routes } from '../../helper/routes'


export function PrivateRoute({ component: Component, ...other }) {
  return (
    <Route
      {...other}
      render={props =>
        authHelper.isTokenExistentAndValid() ?
          (<Component {...props} />) :
          (<Redirect to={{
            pathname: routes.login,
            state: { from: props.location }
          }} />)
      }
    />
  )
}

export function LoginRoute({ component: Component, ...other }) {
  return (
    <Route
      {...other}
      render={props =>
        authHelper.isTokenExistentAndValid() ?
          (<Redirect to={{
            pathname: routes.home,
            state: { from: props.location }
          }} />) :
          (<Component {...props} />)
      }
    />
  )
}
