import React, { useEffect } from "react"
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import HomePage from "./HomePage"
import ApplicationsTable from "./applications/ApplicationsTable"
import ProductsTable from "./products/ProductsTable"
import ClasspartsTable from "./classparts/ClasspartsTable"
import InstancepartsTable from "./instanceparts/InstancepartsTable"
import PatternmixesTable from "./patternmixes/PatternmixesTable"
import PatternsTable from "./patterns/PatternsTable"
import ColorsTable from "./colors/ColorsTable"
import MaterialsTable from "./materials/MaterialsTable"
import GeometriesTable from "./geometries/GeometriesTable"
import MapsTable from "./maps/MapsTable"
import CompositionsTable from "./compositions/CompositionsTable"
import ConfiguredProductsTable from "./configuredProducts/ConfiguredProductsTable"
import ConfiguredPartsTable from "./configuredParts/ConfiguredPartsTable"
import Statistics from "./statistics/Statistics"
import UsersTable from "./users/UsersTable"
import RolesTable from "./roles/RolesTable"
import PermissionsTable from "./permissions/PermissionsTable"
import MainLayout from '../component/layout/MainLayout'
import userActions from '../action/user'
import roleActions from '../action/role'
import permissionActions from '../action/permission'
import applicationActions from '../action/application'
import { routes } from '../helper/routes'



function MainPage({ dispatch, ...props }) {

  useEffect(() => {
    
    Promise.all([
      dispatch(userActions.readProfile()),
      dispatch(applicationActions.readList()),
      dispatch(roleActions.readList()),
      dispatch(permissionActions.readList()),
    ]).catch(err => { })

  }, [dispatch])

  return (
    <MainLayout {...props}>
      <Switch>
        <Route path={routes.home} exact component={HomePage} />
        <Route path={routes.applications} component={ApplicationsTable} />
        <Route path={routes.products} component={ProductsTable} />
        <Route path={routes.classparts} component={ClasspartsTable} />
        <Route path={routes.instanceparts} component={InstancepartsTable} />
        <Route path={routes.patternmixes} component={PatternmixesTable} />
        <Route path={routes.patterns} component={PatternsTable} />
        <Route path={routes.colors} component={ColorsTable} />
        <Route path={routes.materials} component={MaterialsTable} />
        <Route path={routes.geometries} component={GeometriesTable} />
        <Route path={routes.maps} component={MapsTable} />
        <Route path={routes.compositions} component={CompositionsTable} />
        <Route path={routes.configuredProducts} component={ConfiguredProductsTable} />
        <Route path={routes.configuredParts} component={ConfiguredPartsTable} />
        <Route path={routes.statistics} component={Statistics} />
        <Route path={routes.users} component={UsersTable} />
        <Route path={routes.roles} component={RolesTable} />
        <Route path={routes.permissions} component={PermissionsTable} />
      </Switch>
    </MainLayout>
  )
}

export default connect()(MainPage)