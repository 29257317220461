import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import HeaderBar from './HeaderBar'
import DrawerMenu from './DrawerMenu'
import NotFound from "../../container/NotFound"
import { routes, isKnownPath } from '../../helper/routes'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
  },
  content: {
    marginTop: 64,
    flexGrow: 1,
    overflow: 'auto'
  },
}))


export default function MainLayout({ children, location, history }) {
  const classes = useStyles()

  // console.log('history', history)

  const isKnown = isKnownPath(Object.values(routes), location.pathname)
  const notFoundMessage = (<span>Impossibile trovare la pagina richiesta,<br />{location.pathname} non è un percorso valido.</span>)

  return (
    <div className={classes.root}>
      <CssBaseline />
      <HeaderBar pathname={location.pathname} isKnownPath={isKnown} />
      <DrawerMenu pathname={location.pathname} />
      <div className={classes.content}>
        {isKnown ?
          children :
          <NotFound message={notFoundMessage} />
        }
      </div>
    </div>
  )
}

