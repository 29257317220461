import React from 'react'
import { connect } from 'react-redux'
import DynamicTable from '../../component/DynamicTable'
import configuredPartActions from '../../action/configuredPart'
import { localization, secondaryTableOptions, formatTotalElements, getQueryParams } from '../../helper/table'


function ConfiguredPartsTable({ dispatch, totalCount }) {

  const fetchPaginatedData = query => dispatch(configuredPartActions.readPaginated(getQueryParams(query)))

  return (
    <DynamicTable
      columns={[
        { title: "Id", field: "id" },
        { title: "Uid", field: "configured_part_id" },
        { title: "Classe parte", field: "classpart_title" },
        { title: "Istanza parte", field: "instancepart_title" },
        { title: "Patternmix", field: "patternmix_title" },
        { title: "Prodotto configurato", field: "cfg_product_uid" },
        { title: "App", field: "app_title" },
        { title: "Abilitato", field: "is_disabled", render: rowData => !rowData.is_disabled ? 'Si' : 'No' },
        { title: "Extra", field: "extra" },
      ]}
      data={fetchPaginatedData}
      title={formatTotalElements(totalCount)}
      options={secondaryTableOptions}
      localization={localization}
    />
  )
}

const mapStateToProps = store => {
  const { configuredPart } = store
  return {
    totalCount: configuredPart.totalCount
  }
}


export default connect(mapStateToProps)(ConfiguredPartsTable)