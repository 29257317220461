import React from 'react'
import { connect } from 'react-redux'
import DynamicTable from '../../component/DynamicTable'
import patternmixActions from '../../action/patternmix'
import { localization, secondaryTableOptions, formatTotalElements, getQueryParams } from '../../helper/table'


function PatternmixesTable({ dispatch, totalCount }) {

  const fetchPaginatedData = query => dispatch(patternmixActions.readPaginated(getQueryParams(query)))

  return (
    <DynamicTable
      columns={[
        { title: "Id", field: "id" },
        { title: "Uid", field: "patternmix_id" },
        { title: "Titolo", field: "title" },
        { title: "Thumbnail", field: "thumb_filename" },
        { title: "App", field: "app_title" },
        { title: "Abilitato", field: "is_disabled", render: rowData => !rowData.is_disabled ? 'Si' : 'No' },
        { title: "Extra", field: "extra" },
      ]}
      data={fetchPaginatedData}
      title={formatTotalElements(totalCount)}
      options={secondaryTableOptions}
      localization={localization}
    />
  )
}

const mapStateToProps = store => {
  const { patternmix } = store
  return {
    totalCount: patternmix.totalCount
  }
}


export default connect(mapStateToProps)(PatternmixesTable)