import React from 'react'
import Typography from '@material-ui/core/Typography'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import MenuList from '@material-ui/core/MenuList'
import Popover from '@material-ui/core/Popover'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'


const useStyles = makeStyles(theme => ({
  userProfileInfo: {
    padding: theme.spacing(2),
    textAlign: 'center',
    width: 250
  },
  chip: {
    margin: 2,
  }
}))


export default function UserProfilePopover({ user, anchorEl, handleClose, handleClick }) {
  const classes = useStyles()

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const isRoles = user.roles && user.roles.length > 0
  const isPermissions = user.permissions && user.permissions.length > 0

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box className={classes.userProfileInfo}>
        <Typography style={{ fontSize: 20 }}><strong>{user.name}</strong></Typography>
        <Typography style={{ fontSize: 13 }}>{user.email}</Typography>
        {isRoles &&
          <Box mt={1}>
            {user.roles.map((role, i) => <Chip key={'role-' + i} label={role.name} className={classes.chip} size="small" />)}
          </Box>
        }
        {isPermissions &&
          <Box mt={1}>
            {user.permissions.map((permission, i) => <Chip key={'perm-' + i} label={permission.name} className={classes.chip} size="small" />)}
          </Box>
        }
      </Box>
      <Divider variant="middle" />
      <MenuList>
        <MenuItem dense={true} onClick={handleClick}>
          <ListItemIcon style={{ minWidth: 30 }}>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Disconnetti</Typography>
        </MenuItem>
      </MenuList>
    </Popover>

  )
}
