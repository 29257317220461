import React from 'react'
import Box from '@material-ui/core/Box'
import ErrorIcon from '@material-ui/icons/Error'


export default function NotFound({ message }) {

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      css={{ height: '100%' }}
      color="primary.main"
      fontSize={16}
      fontWeight="fontWeightBold"
    >
      <ErrorIcon style={{ fontSize: 54, marginRight: 12 }} />
      <div>
        {message}
      </div>
    </Box>
  )

}