import React from 'react'
import { connect } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import DynamicTable from '../../component/DynamicTable'
import appActions from '../../action/application'
import { localization, secondaryTableOptions, formatTotalElements, getQueryParams } from '../../helper/table'



function ApplicationsTable({ dispatch, totalCount }) {

  const fetchPaginatedData = query => dispatch(appActions.readPaginated(getQueryParams(query)))

  return (
    <DynamicTable
      columns={[
        { title: "Id", field: "id" },
        { title: "Uid", field: "app_id" },
        {
          title: "Icona", field: "img_url",
          render: rowData => rowData.img_url ?
            <Avatar alt="App" src={rowData.img_url} /> :
            <Avatar>{rowData.title.length > 0 ? rowData.title.charAt(0).toUpperCase() : '?'}</Avatar>
        },
        { title: "Titolo", field: "title" },
        { title: "Descrizione", field: "description" },
        { title: "Prefisso Asset", field: "asset_dir_base_url" },
        { title: "Percorso Textures", field: "textures_subpath" },
        { title: "Percorso Modelli", field: "models_subpath" },
        { title: "Cliente", field: "customer_name" },
        { title: "Abilitato", field: "is_disabled", render: rowData => !rowData.is_disabled ? 'Si' : 'No' },
        { title: "Extra", field: "extra" },
      ]}
      data={fetchPaginatedData}
      title={formatTotalElements(totalCount)}
      options={secondaryTableOptions}
      localization={localization}
    />
  )
}

const mapStateToProps = store => {
  const { application } = store
  return {
    totalCount: application.totalCount
  }
}


export default connect(mapStateToProps)(ApplicationsTable)