import React from 'react'
import List from '@material-ui/core/List'
import AppsIcon from '@material-ui/icons/Apps'
// import DashboardIcon from '@material-ui/icons/Dashboard'
// import HomeIcon from '@material-ui/icons/Home'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import ColorLensIcon from '@material-ui/icons/ColorLens'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import CategoryIcon from '@material-ui/icons/Category'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import LoyaltyIcon from '@material-ui/icons/Loyalty'
import ColorizeIcon from '@material-ui/icons/Colorize'
import FlareIcon from '@material-ui/icons/Flare'
import TextureIcon from '@material-ui/icons/Texture'
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting'
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot'
import FavoriteIcon from '@material-ui/icons/Favorite'
import LayersIcon from '@material-ui/icons/Layers'
import Divider from '@material-ui/core/Divider'
import DrawerMenuItem from './DrawerMenuItem'
// import _ from 'lodash'
import { routes } from '../../helper/routes'





export default function DrawerMenuItems({ pathname, permissionSlugs }) {

  const isEqualPath = path => pathname === path
  const isIncludingPath = path => pathname.includes(path)
  const hasPermission = slugs => slugs.some(slug => permissionSlugs.includes(slug))
  
  const canReadUsers = hasPermission(['read-all', 'read-users'])

  return (
    <List component="nav">
      {/* <DrawerMenuItem
        rootItem={{
          title: 'Home',
          icon: <HomeIcon />,
          isSelected: isEqualPath(routes.home),
          toRoute: routes.home,
        }}
      /> */}
      <DrawerMenuItem
        rootItem={{
          title: 'Applicazioni',
          icon: <AppsIcon />,
          isSelected: isEqualPath(routes.applications),
          toRoute: routes.applications,
          isVisible: hasPermission(['read-all', 'read-applications'])
        }}
      />
      <DrawerMenuItem
        rootItem={{
          title: 'Statistiche',
          icon: <EqualizerIcon />,
          isSelected: isEqualPath(routes.statistics),
          toRoute: routes.statistics,
          isVisible: hasPermission(['read-all', 'read-statistics'])
        }}
      />
      <DrawerMenuItem
        rootItem={{
          title: 'Composizioni',
          icon: <FavoriteIcon />,
          isSelected: isIncludingPath(routes.compositions),
          toRoute: routes.compositions,
          isVisible: hasPermission(['read-all', 'read-compositions'])
        }}
        childrenItems={[
          {
            title: 'Prodotti configurati',
            icon: <LoyaltyIcon fontSize="small" />,
            isSelected: isEqualPath(routes.configuredProducts),
            toRoute: routes.configuredProducts,
            isVisible: hasPermission(['read-all', 'read-configued_products'])
          },
          {
            title: 'Parti configurate',
            icon: <PermDataSettingIcon fontSize="small" />,
            isSelected: isEqualPath(routes.configuredParts),
            toRoute: routes.configuredParts,
            isVisible: hasPermission(['read-all', 'read-configued_parts'])
          }
        ]}
      />
      <DrawerMenuItem
        rootItem={{
          title: 'Prodotti',
          icon: <LocalOfferIcon />,
          isSelected: isEqualPath(routes.products),
          toRoute: routes.products,
          isVisible: hasPermission(['read-all', 'read-products'])
        }}
      />
      <DrawerMenuItem
        rootItem={{
          title: 'Classi parte',
          icon: <GroupWorkIcon />,
          isSelected: isEqualPath(routes.classparts),
          toRoute: routes.classparts,
          isVisible: hasPermission(['read-all', 'read-classparts'])
        }}
      />
      <DrawerMenuItem
        rootItem={{
          title: 'Istanze parte',
          icon: <ScatterPlotIcon />,
          isSelected: isEqualPath(routes.instanceparts),
          toRoute: routes.instanceparts,
          isVisible: hasPermission(['read-all', 'read-instanceparts'])
        }}
      />
      <DrawerMenuItem
        rootItem={{
          title: 'Patternmix',
          icon: <LayersIcon />,
          isSelected: isEqualPath(routes.patternmixes),
          toRoute: routes.patternmixes,
          isVisible: hasPermission(['read-all', 'read-patternimxes'])
        }}
      />
      <DrawerMenuItem
        rootItem={{
          title: 'Pattern',
          icon: <ColorLensIcon />,
          isSelected: isEqualPath(routes.patterns),
          toRoute: routes.patterns,
          isVisible: hasPermission(['read-all', 'read-patterns'])
        }}
      />
      <DrawerMenuItem
        rootItem={{
          title: 'Colori',
          icon: <ColorizeIcon />,
          isSelected: isEqualPath(routes.colors),
          toRoute: routes.colors,
          isVisible: hasPermission(['read-all', 'read-colors'])
        }}
      />
      <DrawerMenuItem
        rootItem={{
          title: 'Materiali',
          icon: <FlareIcon />,
          isSelected: isEqualPath(routes.materials),
          toRoute: routes.materials,
          isVisible: hasPermission(['read-all', 'read-materials'])
        }}
      />
      <DrawerMenuItem
        rootItem={{
          title: 'Geometrie',
          icon: <CategoryIcon />,
          isSelected: isEqualPath(routes.geometries),
          toRoute: routes.geometries,
          isVisible: hasPermission(['read-all', 'read-geometries'])
        }}
      />
      <DrawerMenuItem
        rootItem={{
          title: 'Mappe',
          icon: <TextureIcon />,
          isSelected: isEqualPath(routes.maps),
          toRoute: routes.maps,
          isVisible: hasPermission(['read-all', 'read-maps'])
        }}
      />
      {canReadUsers && <Divider variant="middle" />}
      <DrawerMenuItem
        rootItem={{
          title: 'Utenti',
          icon: <AccountCircleIcon />,
          isSelected: isEqualPath(routes.users),
          toRoute: routes.users,
          isVisible: canReadUsers
        }}
        childrenItems={[
          {
            title: 'Ruoli',
            icon: <AccountTreeIcon fontSize="small" />,
            isSelected: isEqualPath(routes.roles),
            toRoute: routes.roles,
            isVisible: hasPermission(['read-all', 'read-roles'])
          },
          {
            title: 'Permessi',
            icon: <VerifiedUserIcon fontSize="small" />,
            isSelected: isEqualPath(routes.permissions),
            toRoute: routes.permissions,
            isVisible: hasPermission(['read-all', 'read-permissions'])
          }
        ]}
      />
    </List>
  )
}

