import { statisticConstants } from '../constant/statistic'
import statisticService from '../service/statistic'
import mainLayoutActions from '../action/mainLayout'
import axiosCommon from '../helper/axiosCommon'



function updateOne(data) {
  const request = () => ({type: statisticConstants.UPDATE_STATISTIC_REQUEST})
  const success = resp => ({type: statisticConstants.UPDATE_STATISTIC_SUCCESS, resp})
  const failure = () => ({type: statisticConstants.UPDATE_STATISTIC_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await statisticService.updateOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Aggiornamento statistica effettuato.'))
      dispatch(readAll())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Aggiornamento statistica fallito. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function deleteOne(id) {
  const request = () => ({type: statisticConstants.DELETE_STATISTIC_REQUEST})
  const success = resp => ({type: statisticConstants.DELETE_STATISTIC_SUCCESS, resp})
  const failure = () => ({type: statisticConstants.DELETE_STATISTIC_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await statisticService.deleteOne(id)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Eliminazione statistica effettuata.'))
      dispatch(readAll())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Eliminazione statistica fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function createOne(data) {
  const request = () => ({type: statisticConstants.CREATE_STATISTIC_REQUEST})
  const success = resp => ({type: statisticConstants.CREATE_STATISTIC_SUCCESS, resp})
  const failure = () => ({type: statisticConstants.CREATE_STATISTIC_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await statisticService.createOne(data)
      dispatch(success(resp))
      dispatch(mainLayoutActions.successSnackbar('Creazione statistica effettuata.'))
      dispatch(readAll())
    }
    catch (error) {
      dispatch(failure())
      dispatch(mainLayoutActions.errorSnackbar('Creazione statistica fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}

function readAll() {
  const request = () => ({type: statisticConstants.READ_STATISTICS_REQUEST})
  const success = resp => ({type: statisticConstants.READ_STATISTICS_SUCCESS, resp})
  const failure = () => ({type: statisticConstants.READ_STATISTICS_FAILURE})
  return async dispatch => {
    dispatch(request())
    try {
      const resp = await statisticService.readAll()
      dispatch(success(resp))
    }
    catch (error) {
      dispatch(failure())
      // dispatch(mainLayoutActions.errorSnackbar('Lettura statistiche fallita. ' + axiosCommon.getFormattedError(error)))
      throw error
    }
  }  
}


export default {
  updateOne,
  deleteOne,
  createOne,
  readAll
}
