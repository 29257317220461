import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { rootReducer } from '../reducer/rootReducer'


export const initialState = {
  mainLayout: {
    isUpdateAvailable: false,
    versionTxt: '',
    notificationBadgeContent: 0,
    isDrawerOpen: true,
    notifications: [],
    isProfileReady: false,
    readProfile: {
      name: '?',
      roles: [],
      permissions: [],
      all_permissions: []
    }
  },
  application: {
    totalCount: 0,
    readAll: [],
    list: [],
    isReady: false,
  },
  statistic: {
    readAll: [],
    isReady: false,
  },
  composition: {
    totalCount: 0,
    readAll: [],
    isReady: false,
  },
  configuredProduct: {
    totalCount: 0,
    readAll: [],
    isReady: false,
  },
  configuredPart: {
    totalCount: 0,
    readAll: [],
    isReady: false,
  },
  product: {
    totalCount: 0,
    readAll: [],
    isReady: false,
  },
  classpart: {
    totalCount: 0,
    readAll: [],
    isReady: false,
  },
  instancepart: {
    totalCount: 0,
    readAll: [],
    isReady: false,
  },
  patternmix: {
    totalCount: 0,
    readAll: [],
    isReady: false,
  },
  pattern: {
    totalCount: 0,
    readAll: [],
    isReady: false,
  },
  color: {
    totalCount: 0,
    readAll: [],
    isReady: false,
  },
  material: {
    totalCount: 0,
    readAll: [],
    isReady: false,
  },
  geometry: {
    totalCount: 0,
    readAll: [],
    isReady: false,
  },
  map: {
    readAll: [],
    totalCount: 0,
    isReady: false,
  },
  user: {
    readAll: [],
    totalCount: 0,
    isReady: false,
  },
  role: {
    totalCount: 0,
    readAll: [],
    list: [],
    isReady: false,
  },
  permission: {
    totalCount: 0,
    readAll: [],
    list: [],
    isReady: false,
  },

}


export const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(thunk)
)
