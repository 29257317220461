import React from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
// import _ from 'lodash'


const useStyles = makeStyles(theme => ({
  resize: {
    fontSize: 14
  },
}))


export default function TableEditTextField({ textId, options, props }) {
  // console.log('TableEditTextField', props)
  const classes = useStyles()

  const handleChange = e => props.onChange(e.target.value)
  
  return (
    <form noValidate autoComplete="off">
      <TextField
        id={textId}
        helperText={options && options.error ? 'Campo richiesto' : ''}
        error={options ? options.error : false}
        value={props.value || ''}
        onChange={handleChange}
        fullWidth={true}
        multiline={options ? options.multiline : false}
        spellCheck={false}
        InputProps={{ classes: { input: classes.resize } }}
      />
    </form>

  )
}