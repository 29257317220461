import React from 'react'
import { connect } from 'react-redux'
import DynamicTable from '../../component/DynamicTable'
import materialActions from '../../action/material'
import { localization, secondaryTableOptions, formatTotalElements, getQueryParams} from '../../helper/table'



function MaterialsTable({ dispatch, totalCount }) {

  const fetchPaginatedData = query => dispatch(materialActions.readPaginated(getQueryParams(query)))

  return (
    <DynamicTable
      columns={[
        { title: "Id", field: "id" },
        { title: "Uid", field: "material_id" },
        { title: "Titolo", field: "title" },
        { title: "App", field: "app_title" },
        { title: "Abilitato", field: "is_disabled", render: rowData => !rowData.is_disabled ? 'Si' : 'No' },
        { title: "Extra", field: "extra" },
        { title: "Parametri", field: "params" },
      ]}
      data={fetchPaginatedData}
      title={formatTotalElements(totalCount)}
      options={secondaryTableOptions}
      localization={localization}
    />
  )
}

const mapStateToProps = store => {
  const { material } = store
  return {
    totalCount: material.totalCount
  }
}


export default connect(mapStateToProps)(MaterialsTable)