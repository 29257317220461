import React from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx';
import { Redirect } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'
import NotificationsIcon from '@material-ui/icons/Notifications'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import mainLayoutActions from '../../action/mainLayout'
import authActions from '../../action/auth'
import UserProfilePopover from './UserProfilePopover'
import NotificationPopover from './NotificationPopover'
import AlertDialog from '../dialog/AlertDialog'
import { routes } from '../../helper/routes'


const drawerWidth = 230

const useStyles = makeStyles(theme => ({
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#292929'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  avatar: {
    color: theme.palette.primary.contrastText,
    // backgroundColor: theme.palette.primary.main,
  }
}))



function HeaderBar({ pathname, isKnownPath, dispatch, isDrawerOpen, notificationBadgeContent, isProfileReady, loggedUser, isUpdateAvailable, versionTxt }) {
  const classes = useStyles()

  const [toLogin, setToLogin] = React.useState(false)
  const [isLogoutDialogOpen, setIsLogoutDialogOpen] = React.useState(false)
  const [anchorElProfile, setAnchorElProfile] = React.useState(null)
  const [anchorElChangelog, setAnchorElChangelog] = React.useState(null)

  const handleLogoutConfirm = async () => {
    handleProfileMenuClose()
    await dispatch(authActions.logout())
    handleLogoutDialogClose()
    setToLogin(true)
  }

  const handleProfileMenuOpen = event => {
    setAnchorElProfile(event.currentTarget)
  }

  const handleProfileMenuClose = () => {
    setAnchorElProfile(null)
  }

  const handleNotificationMenuOpen = event => {
    setAnchorElChangelog(event.currentTarget)
  }

  const handleChangelogMenuClose = () => {
    setAnchorElChangelog(null)
  }

  const handleLogoutDialogOpen = () => {
    setIsLogoutDialogOpen(true)
  }

  const handleLogoutDialogClose = () => {
    setIsLogoutDialogOpen(false)
  }

  const avatarChar = loggedUser.name.charAt(0).toUpperCase()
  const splitPath = pathname.split('/')
  let appBarTitle = 'Oops...'
  if (isKnownPath) { appBarTitle = splitPath[1].charAt(0).toUpperCase() + splitPath[1].slice(1) }
  if (pathname === routes.home) { appBarTitle = '' }

  if (toLogin === true) {
    return <Redirect to={routes.login} />
  }
  return (
    <AppBar position="absolute" className={clsx(classes.appBar, isDrawerOpen && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => dispatch(mainLayoutActions.setDrawerOpen())}
          className={clsx(classes.menuButton, isDrawerOpen && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.grow}>
          {appBarTitle}
        </Typography>
        
        <IconButton onClick={handleNotificationMenuOpen} aria-label="visualizza notifiche" style={{ color: '#eee', marginRight: 8 }}>
          <Badge badgeContent={notificationBadgeContent} invisible={notificationBadgeContent === 0} color="primary" >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        
        {isProfileReady &&
          <IconButton edge="end" onClick={handleProfileMenuOpen} color="inherit">
            <Avatar className={classes.avatar} >
              {avatarChar}
            </Avatar>
          </IconButton>
        }
        <UserProfilePopover user={loggedUser} anchorEl={anchorElProfile} handleClick={handleLogoutDialogOpen} handleClose={handleProfileMenuClose} />
        <NotificationPopover anchorEl={anchorElChangelog} handleClose={handleChangelogMenuClose} isUpdate={isUpdateAvailable} versionTxt={versionTxt} />
      </Toolbar>
      <AlertDialog
        title="Conferma disconnessione"
        content="Uscire dall'applicazione?"
        open={isLogoutDialogOpen}
        handleClose={handleLogoutDialogClose}
        handleConfirm={handleLogoutConfirm}
      />
    </AppBar>
  )
}


const mapStateToProps = store => {
  const { mainLayout } = store
  return {
    isDrawerOpen: mainLayout.isDrawerOpen,
    notificationBadgeContent: mainLayout.notificationBadgeContent,
    versionTxt: mainLayout.versionTxt,
    isUpdateAvailable: mainLayout.isUpdateAvailable,
    isProfileReady: mainLayout.isProfileReady,
    loggedUser: mainLayout.readProfile
  }
}

export default connect(mapStateToProps)(HeaderBar)