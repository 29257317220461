import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import UpdateIcon from '@material-ui/icons/Update'
import MenuList from '@material-ui/core/MenuList'
import Popover from '@material-ui/core/Popover'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'


const useStyles = makeStyles(theme => ({
  boxContent: {
    padding: theme.spacing(2),
    width: 350
  },
  chip: {
    margin: 2,
  }
}))


function NotificationBox({ notification }) {
  const { title, date, content } = notification
  return (
    <Box pt={1}>
      <Typography style={{ fontSize: 16, lineHeight: 0.8 }}>
        <strong>{title}</strong>
      </Typography>
      <Typography>
        <strong style={{ fontSize: 12 }}>{date}</strong>
      </Typography>
      <Box pt={1}>
        {content.split('\n').map((item, i) => <div key={i}>{item}</div>)}
      </Box>
    </Box>
  )
}

const refreshPage = () => window.location.reload(true)



export default function NotificationPopover({ anchorEl, handleClose, isUpdate, versionTxt }) {
  const classes = useStyles()

  const open = Boolean(anchorEl)
  const id = open ? 'notification-popover' : undefined

  const notifications = [
    {
      "title": `Versione ${process.env.REACT_APP_VERSION}`,
      // "date": '07/02/2020',
      "content": isUpdate ? 'È disponibile la nuova versione ' + versionTxt : ''
    }
  ]

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Box className={classes.boxContent}>
        {notifications.map((notification, i) => <NotificationBox key={i} notification={notification} />)}
      </Box>
      {isUpdate && <Divider variant="middle" />}
      {isUpdate &&
        <MenuList>
          <MenuItem dense={true} onClick={refreshPage}>
            <ListItemIcon style={{ minWidth: 30 }}>
              <UpdateIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Aggiorna</Typography>
          </MenuItem>
        </MenuList>
      }
    </Popover>
  )
}
