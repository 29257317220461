import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Tooltip from '@material-ui/core/Tooltip'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'


const useStyles = makeStyles(theme => ({
  itemIconPrimary: {
    marginLeft: 6,
  },
  itemIconSecondary: {
    marginLeft: 8,
  },
  itemSecondaryText: {
    fontSize: 14,
    lineHeight: 0.8
  },
  itemSecondary: {
    height: 10,
    lineHeight: 0.8
  },
}))


function TooltipListItemIcon({ title, icon, className }) {
  return (
    <Tooltip enterDelay={1000} title={title}>
      <ListItemIcon className={className}>
        {icon}
      </ListItemIcon>
    </Tooltip>
  )
}

function MenuItemPrimary({ itemData, open, hasChildren }) {
  const classes = useStyles()
  const { title, icon, isSelected, toRoute, isVisible = true } = itemData
  return isVisible ?
  (
    <ListItem button selected={isSelected} component={Link} to={toRoute} >
      <TooltipListItemIcon title={title} icon={icon} className={classes.itemIconPrimary} />
      <ListItemText primary={title} />
      {hasChildren ? (open ? <ExpandLess color='action' /> : <ExpandMore color='action' />) : null}
    </ListItem>
  ) :
  null
}

function MenuItemSecondary({ itemData }) {
  const classes = useStyles()
  const { title, icon, isSelected, toRoute, isVisible = true } = itemData
  return isVisible ?
  (
    <ListItem button selected={isSelected} component={Link} to={toRoute} >
      <TooltipListItemIcon title={title} icon={icon} className={classes.itemIconSecondary} />
      <ListItemText className={classes.itemSecondary} classes={{ primary: classes.itemSecondaryText }} primary={title} />
    </ListItem>
  ) :
  null
}


export default function DrawerMenuItem({ rootItem, childrenItems = [] }) {
  const isOpen = rootItem.isSelected || childrenItems.reduce((acc, item) => acc = acc || item.isSelected, false)
  const hasChildren = childrenItems.length > 0
  return (
      <div>
        <MenuItemPrimary itemData={rootItem} open={isOpen} hasChildren={hasChildren} />
        {hasChildren &&
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {childrenItems.map((child, i) => <MenuItemSecondary key={i} itemData={child} />)}
            </List>
          </Collapse>
        }
      </div>
    )
}
