import React from 'react'
import { connect } from 'react-redux'
import clsx from 'clsx'
import _ from 'lodash'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import mioLogo from '../../resource/mio-logo.png'
import Box from '@material-ui/core/Box'
import DrawerMenuItems from './DrawerMenuItems'
import mainLayoutActions from '../../action/mainLayout'
import { routes } from '../../helper/routes'
import Link from '@material-ui/core/Link'
import ReactRouterLink from '../../component/ReactRouterLink'

const drawerWidth = 230

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    overflowX: "hidden",
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(8),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8.7),
    },
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    ...theme.mixins.toolbar,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "flex-end",
    paddingLeft: '8px',
    paddingBottom: '8px',
    height: '100%',
    ...theme.mixins.toolbar,
  },
  logo: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "34%"
  },
}))


function DrawerMenu({ dispatch, isDrawerOpen, pathname, allUserPermissionSlugs }) {
  const classes = useStyles()

  const handleDrawerOpen = () => {
    dispatch(mainLayoutActions.setDrawerOpen())
  }

  const handleDrawerClose = () => {
    dispatch(mainLayoutActions.setDrawerClose())
  }

  return (
    <Drawer
      variant="permanent"
      classes={{ paper: clsx(classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose) }}
      open={isDrawerOpen}>

      <Link to={routes.home} component={ReactRouterLink}>
        <Box className={classes.logoContainer}>
          <img src={mioLogo} alt="main-logo" className={classes.logo} />
        </Box>
      </Link>

      <Divider />
      <Box mt={2}>
        <DrawerMenuItems pathname={pathname} permissionSlugs={allUserPermissionSlugs} />
      </Box>
      <Box className={classes.toolbarIcon}>
        <Tooltip
          enterDelay={1000}
          placement="right"
          title={isDrawerOpen ? "Riduci Menu" : "Espandi Menu"}>
          <IconButton onClick={isDrawerOpen ? handleDrawerClose : handleDrawerOpen} >
            {isDrawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Tooltip>
      </Box>
    </Drawer>
  )
}


const mapStateToProps = store => {
  const { mainLayout } = store
  return {
    isDrawerOpen: mainLayout.isDrawerOpen,
    allUserPermissionSlugs: _.map(mainLayout.readProfile.all_permissions, 'slug')
  }
}

export default connect(mapStateToProps)(DrawerMenu)
