import React from 'react'
import { withRouter } from "react-router-dom"
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
// import Button from '@material-ui/core/Button'
// import mainLayoutActions from '../action/mainLayout'


function HomePage({ history }) {

  // const handleClick = () => {
  //   history.push('dc')
  // }

  return (
    <Box display='flex' justifyContent="center" alignItems="center" css={{ height: '90%' }}>
      <Typography variant="h4">
        Benvenuto a MIO studio
      </Typography>
      {/* <Button variant="contained" onClick={handleClick}>Test Componente Redirect</Button> */}
    </Box>
  )
}

export default withRouter(HomePage)
